import {$, $jqHtml, Pages} from "./constants";
import renderHomePage from "./pages/home";
import ImageSwiper from "./scripts/image-swiper";
import InitNavigation from "./scripts/navigation";
import SetupAlerts from "./scripts/alerts";
import Popups from "./scripts/popups";
import RevealAnimation from "./scripts/revealAnimation";

$(() => {
    console.log('running page scripts');
    if($jqHtml.hasClass(Pages.HOME)){
        renderHomePage();
    }

    InitNavigation();
    ImageSwiper('.image-swiper-container');
    SetupAlerts();
    Popups();
    RevealAnimation('revealAnimation');
    RevealAnimation('image-swiper');
});
