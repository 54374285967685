import {$, $jqHtml, Pages} from "../constants";

export default function InitNavigation() {
    const $header = $('#header');
    const scrollThreshold = window.innerHeight > 500 ? window.innerHeight : 500;
    const scrollClass = "fixed";

    //setup fixed header
    $(document).scroll(function(){
        if($(this).scrollTop() >= scrollThreshold){
            $header.addClass(scrollClass);
        }else{
            $header.removeClass(scrollClass);
        }
    });

    //setup menu button
    $('#menu-button').click( function(){
        $header.toggleClass('active');
    });

    //setup sub navigation buttons
    $('.nav-menu li').on('click touch touchstart', function(e) {
        const $target = $(e.target);
        if($target.hasClass('sub-menu-trigger')) {
            e.preventDefault();
            $('.nav-menu > ul > li').removeClass('active');
            $target.parents('li').addClass('active');
        }
    })
}
