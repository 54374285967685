import Swiper from "../../../dependencies/node_modules/swiper/dist/js/swiper.min"

export default function ImageSwiper(selector) {
    new Swiper(selector, {
        slidesPerView: 4,
        spaceBetween: 25,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        autoplay: {
            delay: 5000,
        }
    });
}
