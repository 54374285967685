import {$} from "../constants";
import Swiper from "../../../dependencies/node_modules/swiper/dist/js/swiper.min"
import RevealAnimation from "../scripts/revealAnimation";

class Home {
    constructor() {
    }

    render() {
        console.log('rendering home page');

        //configure testimonial slides
        new Swiper('.testimonial-slides', {
            slidesPerView: 1,
            spaceBetween: 0,
            effect: 'fade',
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            autoplay: {
                delay: 15000,
            }
        });

        //configure home arrow functonality
        $('#home-arrow').click(function(){
            $('html, body').animate({ scrollTop: $('.explore-section').offset().top }, 1000);
        });

        //configure reveal animation elements
        RevealAnimation('feature');
        RevealAnimation('box-link');
    }
}

export default function renderHomePage() {
    (new Home()).render();
}
