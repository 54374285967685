import ScrollReveal from '../../../dependencies/node_modules/scrollreveal';

export default function RevealAnimation(classname, customConfig) {
    const config = {
        reset: true,
        interval: 100,
        scale: 0.65,
        duration: 1000,
        ...customConfig
    };
    ScrollReveal().reveal(`.${classname}`, config);
}
